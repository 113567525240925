import React from 'react';
export default () => (
    <svg
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 330.314 330.314"
        style={{
            enableBackground: 'new 0 0 330.314 330.314',
            maxWidth: '100%'
        }}
    >
        <g>
            <path
                d="M298.762,119.175c-0.611-3.672-5.508-3.672-5.508,0c-1.836,50.796-1.224,101.592-1.836,151.775
		c0,6.732,3.672,29.377-0.612,34.885c-7.955,11.628-25.092,7.344-37.332,7.956c-20.195,0.611-39.779,1.224-59.976,1.836
		c1.836-6.12-0.612-14.076-0.612-20.196c-0.611-21.42-1.224-42.84-1.836-63.648c-1.224-36.107,3.061-80.171-7.956-115.056
		c15.301-0.612,31.212-1.224,46.513-1.836c4.896,0,64.26-4.896,61.812,1.224c-0.612,1.224,0.612,1.836,1.225,1.224
		c4.283-7.956-9.181-11.016-27.54-12.24c9.18-29.376,16.523-94.86-25.704-104.04c-43.452-9.18-69.156,43.452-79.561,76.5
		c-1.836-0.612-3.06-0.612-4.896-0.612c-4.896-31.824-34.884-78.948-71.604-70.38C35.602,17.583,41.11,76.947,55.186,111.219
		c0,0,0,0.612,0.612,0.612c-13.464,1.836-25.092,6.732-28.152,22.644c-0.612,1.836,0.612,3.06,1.224,4.284
		c-4.284,62.424,1.224,124.848,6.732,187.271c0,2.448,1.836,4.284,4.284,4.284c85.068-0.611,170.136-4.284,255.816-6.12
		c3.06,0,5.508-2.447,5.508-5.508C303.046,251.979,304.882,185.271,298.762,119.175z M262.042,104.487
		c-30.6-1.836-74.664,1.836-81.396,1.836c-1.225,0-2.448,0-3.672,0c0-2.448,0-5.508-0.612-7.956c8.568,1.224,17.136,0,24.479-4.284
		c2.448-1.224,0.612-5.508-1.836-4.896c-7.956,2.448-16.523,3.672-24.479,3.06c-0.612-0.612-0.612-1.836-1.225-2.448
		c-0.611-1.836-1.836-3.672-3.672-5.508c30.601-4.896,85.681-22.032,87.517-56.304C266.938,49.407,262.042,86.127,262.042,104.487z
		 M228.994,10.239c11.016-0.612,18.36,3.06,23.256,9.792c0,0-0.612,0.612-0.612,1.224c-8.567,36.108-52.02,49.572-85.067,56.304
		C179.422,50.019,194.722,12.075,228.994,10.239z M153.106,86.127c1.224,0,1.836,0,3.06,0s1.836-0.612,1.836-1.836
		c4.896,1.224,7.956,9.18,9.181,12.852c0.611,3.06,0.611,5.508,1.224,8.568c-4.284,0-7.956,0-12.24,0
		c-1.836-3.672-4.284-8.568-4.896-12.852c0.612-1.224,0.612-2.448,0-3.06C151.27,89.187,151.882,87.351,153.106,86.127z
		 M80.89,16.971c36.108-13.464,58.752,34.272,68.544,61.812c-0.612,0.612-1.224,1.224-1.224,1.224
		c-16.524-5.508-32.436-12.852-46.512-23.868c-12.24-9.792-19.584-23.868-29.988-34.272C74.77,20.031,77.83,18.195,80.89,16.971z
		 M64.366,27.987c4.896,14.688,20.808,28.152,31.824,36.72c14.688,11.016,31.212,18.36,48.96,20.808
		c-0.612,1.836-1.224,3.672-0.612,5.508c0,0.612,0,0.612,0,1.224c-6.12,1.224-12.852,0-18.36-1.836
		c-2.448-1.224-4.896,3.06-2.448,4.284c6.732,5.508,14.688,6.12,22.032,3.06c1.224,3.672,3.672,6.732,5.508,9.18
		c-28.764,0.612-58.14,0.612-86.292,3.06c-1.224,0-1.836,0-3.06,0c0-0.612,0-1.224,0-1.836
		C53.962,84.291,46.006,48.183,64.366,27.987z M101.086,318.687c-12.852,0.612-29.988,3.672-42.84,1.225
		c-18.36-3.673-15.912-12.853-17.136-29.988c-3.672-50.185-4.896-100.98-6.12-151.164c0.612-0.612,1.224-1.224,1.224-2.448
		c6.12-20.196,65.484-20.808,105.264-20.196c-9.18,66.096,4.896,134.64,9.18,200.736
		C134.134,318.075,117.61,318.075,101.086,318.687z M173.914,316.851c-5.508,0-10.404,0.612-15.912,0.612
		c0-66.708-8.568-133.416-10.404-200.736c11.628,0,21.42,0.612,26.316,0.612c1.836,0,4.284,0,6.12,0
		c-9.18,35.496-1.836,78.336-1.224,114.443c0.611,28.152,2.447,57.528,5.508,84.456
		C181.258,316.238,177.586,316.238,173.914,316.851z"
            />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
    </svg>
);
